/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
// @mui material components
import { createTheme } from "@mui/material/styles";
// import Fade from "@mui/material/Fade";

// ELLE ET LUI -EGALITE React base styles
import colors from "assets/User/theme/base/colors";
import breakpoints from "assets/User/theme/base/breakpoints";
import typography from "assets/User/theme/base/typography";
import boxShadows from "assets/User/theme/base/boxShadows";
import borders from "assets/User/theme/base/borders";
import globals from "assets/User/theme/base/globals";

// ELLE ET LUI -EGALITE React helper functions
import boxShadow from "assets/User/theme/functions/boxShadow";
import hexToRgb from "assets/User/theme/functions/hexToRgb";
import linearGradient from "assets/User/theme/functions/linearGradient";
import pxToRem from "assets/User/theme/functions/pxToRem";
import rgba from "assets/User/theme/functions/rgba";

// ELLE ET LUI -EGALITE React components base styles for @mui material components
import list from "components/User/list";
import listItem from "components/User/list/listItem";
import listItemText from "components/User/list/listItemText";
import card from "components/User/card";
import cardMedia from "components/User/card/cardMedia";
import cardContent from "components/User/card/cardContent";
import button from "components/User/button";
import iconButton from "components/User/iconButton";
import input from "components/User/form/input";
import inputLabel from "components/User/form/inputLabel";
import inputOutlined from "components/User/form/inputOutlined";
import textField from "components/User/form/textField";
import menu from "components/User/menu";
import menuItem from "components/User/menu/menuItem";
import switchButton from "components/User/form/switchButton";
import divider from "components/User/divider";
import tableContainer from "components/User/table/tableContainer";
import tableHead from "components/User/table/tableHead";
import tableCell from "components/User/table/tableCell";
import linearProgress from "components/User/linearProgress";
import breadcrumbs from "components/User/breadcrumbs";
import slider from "components/User/slider";
import avatar from "components/User/avatar";
import tooltip from "components/User/tooltip";
import appBar from "components/User/appBar";
import tabs from "components/User/tabs";
import tab from "components/User/tabs/tab";
import stepper from "components/User/stepper";
import step from "components/User/stepper/step";
import stepConnector from "components/User/stepper/stepConnector";
import stepLabel from "components/User/stepper/stepLabel";
import stepIcon from "components/User/stepper/stepIcon";
import select from "components/User/form/select";
import formControlLabel from "components/User/form/formControlLabel";
import formLabel from "components/User/form/formLabel";
import checkbox from "components/User/form/checkbox";
import radio from "components/User/form/radio";
import autocomplete from "components/User/form/autocomplete";
import flatpickr from "components/User/flatpickr";
import container from "components/User/container";
import popover from "components/User/popover";
import buttonBase from "components/User/buttonBase";
import icon from "components/User/icon";
import svgIcon from "components/User/svgIcon";
import link from "components/User/link";
import dialog from "components/User/dialog";
import dialogTitle from "components/User/dialog/dialogTitle";
import dialogContent from "components/User/dialog/dialogContent";
import dialogContentText from "components/User/dialog/dialogContentText";
import dialogActions from "components/User/dialog/dialogActions";

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...flatpickr,
        ...container,
      },
    },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiButton: { ...button },
    MuiIconButton: { ...iconButton },
    MuiInput: { ...input },
    MuiInputLabel: { ...inputLabel },
    MuiOutlinedInput: { ...inputOutlined },
    MuiTextField: { ...textField },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiSwitch: { ...switchButton },
    MuiDivider: { ...divider },
    MuiTableContainer: { ...tableContainer },
    MuiTableHead: { ...tableHead },
    MuiTableCell: { ...tableCell },
    MuiLinearProgress: { ...linearProgress },
    MuiBreadcrumbs: { ...breadcrumbs },
    MuiSlider: { ...slider },
    MuiAvatar: { ...avatar },
    MuiTooltip: { ...tooltip },
    MuiAppBar: { ...appBar },
    MuiTabs: { ...tabs },
    MuiTab: { ...tab },
    MuiStepper: { ...stepper },
    MuiStep: { ...step },
    MuiStepConnector: { ...stepConnector },
    MuiStepLabel: { ...stepLabel },
    MuiStepIcon: { ...stepIcon },
    MuiSelect: { ...select },
    MuiFormControlLabel: { ...formControlLabel },
    MuiFormLabel: { ...formLabel },
    MuiCheckbox: { ...checkbox },
    MuiRadio: { ...radio },
    MuiAutocomplete: { ...autocomplete },
    MuiPopover: { ...popover },
    MuiButtonBase: { ...buttonBase },
    MuiIcon: { ...icon },
    MuiSvgIcon: { ...svgIcon },
    MuiLink: { ...link },
    MuiDialog: { ...dialog },
    MuiDialogTitle: { ...dialogTitle },
    MuiDialogContent: { ...dialogContent },
    MuiDialogContentText: { ...dialogContentText },
    MuiDialogActions: { ...dialogActions },
  },
});

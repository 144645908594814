/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

=========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/ // src/components/ArticlesTableData.js
import React, { useEffect, useState } from "react";
import MDBox from "components/Admin/MDBox";
import MDTypography from "components/Admin/MDTypography";
import MDAvatar from "components/Admin/MDAvatar";
import MDBadge from "components/Admin/MDBadge";
import MKButton from "components/User/MKButton";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getArticles, getUsers, updateArticle } from "request/api";
import { useNavigate } from "react-router-dom";

// Function to convert title to a URL-friendly slug
const slugify = (title) => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, "") // Remove non-alphanumeric characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
};

const icon = <Icon>person</Icon>;

const ArticlesTableData = ({ onEdit }) => {
  const [Articles, setArticles] = useState([]);
  const [users, setUsers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getArticles();
        const usersData = await getUsers();
        const usersMap = usersData.reduce((acc, user) => {
          acc[user._id] = user;
          return acc;
        }, {});

        setUsers(usersMap);
        setArticles(data);
      } catch (error) {
        console.error("Error fetching Articles:", error);
      }
    };

    // Initial fetch
    fetchArticles();

    // Polling every 10 seconds
    const intervalId = setInterval(fetchArticles, 1000); // Adjust the interval as needed

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleVisibilityToggle = async (articleId, currentVisibility) => {
    try {
      await updateArticle(articleId, { isVisible: !currentVisibility });
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article._id === articleId ? { ...article, isVisible: !currentVisibility } : article
        )
      );
    } catch (error) {
      console.error("Error updating article visibility:", error);
    }
  };

  const Author = ({ authorId }) => {
    const user = users[authorId];
    return user ? (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar>{icon}</MDAvatar>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {user.username}
          </MDTypography>
          <MDTypography variant="caption">{user.email}</MDTypography>
        </MDBox>
      </MDBox>
    ) : (
      <MDTypography variant="caption">Unknown Author</MDTypography>
    );
  };

  return {
    columns: [
      { Header: "No.", accessor: "number", width: "10%", align: "center" },
      { Header: "Title", accessor: "title", width: "20%", align: "center" },
      { Header: "Author", accessor: "author", align: "center" },
      { Header: "Status", accessor: "isVisible", align: "center" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: Articles.map((article, index) => ({
      number: <MDTypography variant="caption">{index + 1}</MDTypography>,
      title: (
        <MDTypography variant="caption" fontWeight="bold">
          {article.title}
        </MDTypography>
      ),
      author: <Author authorId={article.author} />,
      isVisible: (
        <MDBox ml={-1} display="flex" justifyContent="center">
          <MDBadge
            badgeContent={article.isVisible ? "Active" : "Inactive"}
            color={article.isVisible ? "success" : "dark"}
            variant="gradient"
            size="sm"
            sx={{ marginRight: 1 }}
          />
          <Switch
            checked={article.isVisible}
            onChange={() => handleVisibilityToggle(article._id, article.isVisible)}
            color="primary"
          />
        </MDBox>
      ),
      createdAt: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {new Date(article.createdAt).toLocaleDateString()}
        </MDTypography>
      ),
      action: (
        <MDBox display="flex" justifyContent="center">
          <MKButton
            color="info"
            startIcon={<VisibilityIcon />}
            variant="text"
            size="small"
            sx={{ marginRight: 1 }}
            onClick={() => navigate(`/articles/${slugify(article.title)}`)}
          >
            View
          </MKButton>
          <MKButton
            color="warning"
            startIcon={<EditIcon />}
            variant="text"
            size="small"
            sx={{ marginRight: 1 }}
            onClick={() => onEdit(article)}
          >
            Edit
          </MKButton>
        </MDBox>
      ),
    })),
  };
};

export default ArticlesTableData;

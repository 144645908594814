import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import MDSnackbar from "components/User/MDSnackbar"; // Ajustez le chemin en fonction de votre structure de projet
import useElapsedTime from "hooks/useElapsedTime"; // Ajustez le chemin en fonction de votre structure de projet

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: "", color: "info" });
  const [alertTimestamp, setAlertTimestamp] = useState(null);
  const elapsedTime = useElapsedTime(alertTimestamp);

  useEffect(() => {
    let timer;

    if (snackbar.open) {
      setAlertTimestamp(new Date());

      timer = setTimeout(() => {
        setSnackbar((prev) => ({ ...prev, open: false }));
      }, 6000);
    }

    return () => clearTimeout(timer);
  }, [snackbar.open]);

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {children}
      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.color === "success" ? "check" : "warning"}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        title="ELLE ET LUI -EGALITE"
        content={snackbar.message}
        dateTime={alertTimestamp ? ` (${elapsedTime})` : ""}
        open={snackbar.open}
        close={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      />
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSnackbar = () => useContext(SnackbarContext);

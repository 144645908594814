/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/User/MKBox";
import Container from "@mui/material/Container";
import MKTypography from "components/User/MKTypography";
const InvolveImage =
  "https://www.wvi.org/sites/default/files/styles/landscape_480x360/public/2024-01/W395-0265-051.jpg?itok=1sgWUFG3";
function Involve() {
  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox
              component="img"
              src={InvolveImage}
              alt="Get Involved"
              width="100%"
              borderRadius="md"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              <span style={{ color: " #048404" }}>Get Involve</span>
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Want to <strong>get involved</strong> in our mission? Discover the different ways to{" "}
              <em>contribute</em>, whether by becoming <strong>a partner</strong>, offering your
              time, or making a donation.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Involve;

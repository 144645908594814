/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
const BlogImage =
  "https://genderstandards.org/wp-content/uploads/2020/05/GPC-Horizontal-Seal-1-300x123.png";
function Blog() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <MKBox component="img" src={BlogImage} alt="Blog" width="100%" borderRadius="md" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKTypography variant="h3" fontWeight="bold" mb={2}>
            <span style={{ color: " #048404" }}>Blog</span>
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Discover our <strong>articles</strong> and <em>news</em> on a variety of topics, ranging
            from the projects we are carrying out to reflections on important social issues.
          </MKTypography>
        </Grid>
      </Grid>
    </>
  );
}

export default Blog;

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// ELLE ET LUI -EGALITE React pages
import Register from "pages/User/LandingPages/Register";

export default function RegisterPage() {
  return <Register />;
}

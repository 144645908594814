/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
export default function sidenavLogoLabel(theme, ownerState) {
  const { functions, transitions, typography, breakpoints } = theme;
  const { miniSidenav } = ownerState;

  const { pxToRem } = functions;
  const { fontWeightMedium } = typography;

  return {
    ml: 0.5,
    fontWeight: fontWeightMedium,
    wordSpacing: pxToRem(-1),
    transition: transitions.create("opacity", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav ? 0 : 1,
    },
  };
}

/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { SnackbarProvider } from "context/Snackbar";
import { RedirectProvider } from "context/RedirectContext";
import { LoaderProvider } from "context/LoaderContext";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { Routes, Route, useLocation } from "react-router-dom";
import MDBox from "components/Admin/MDBox";
import Sidenav from "components/Admin/Sidenav";
import Configurator from "components/Admin/Configurator";
import theme from "theme/User";
import themeDark from "assets/Admin/theme-dark";
import Home from "layouts/User/pages/home";
import brandWhite from "assets/Admin/images/logo-ct.png";
import brandDark from "assets/Admin/images/logo-ct-dark.png";
import adminroutes from "admin/adminroutes";
import DetailledArticle from "layouts/User/pages/landing-pages/Articles/DetailledArticle";
import routes from "routes";
import { jwtDecode } from "jwt-decode";
import { getUsers } from "request/api";
import ResetPassword from "layouts/User/pages/authentication/Reset-password";
import Terms from "layouts/User/pages/landing-pages/Terms";

const verifyTokenAndGetUser = async (token) => {
  if (!token) {
    console.error("Token does not exist.");
    return { isValid: false, hasRequiredRoles: false };
  }

  try {
    // Decode the token
    const decodedToken = jwtDecode(token);
    const userId = String(decodedToken.id); // Convert to string if necessary
    const userRoles = Array.isArray(decodedToken.roles)
      ? decodedToken.roles
      : JSON.parse(decodedToken.roles || "[]");

    // Get users from API
    const users = await getUsers();
    // Check if the user with the given ID exists
    const user = users.find((user) => {
      const dbUserId = String(user._id); // Convert MongoDB ObjectId to string
      console.log(`Comparing user ID from token (${userId}) with database ID (${dbUserId})`);
      return dbUserId === userId;
    });

    if (!user) {
      console.error(`User with ID ${userId} does not exist in the database.`);
      return { isValid: false, hasRequiredRoles: false };
    }

    // Check if the user is visible
    if (!user.isVisible) {
      console.error(`User with ID ${userId} is not visible.`);
      return { isValid: false, hasRequiredRoles: false };
    }

    // Proceed with further logic if user exists and is visible
    const dbUserRoles = Array.isArray(user.roles) ? user.roles : [];

    // Define required roles as a JSON array
    const requiredRoles = ["admin"]; // Replace with your required roles or fetch dynamically

    // Ensure requiredRoles is an array of strings and compare with userRoles
    const hasRequiredRoles = requiredRoles.every((role) => userRoles.includes(role));

    console.log(
      `Token exists: true\nUser exists in database: true\nUser isVisible: ${user.isVisible}\nDecoded Roles: ${JSON.stringify(
        userRoles
      )}\nRoles in database: ${JSON.stringify(dbUserRoles)}\nHas Required Roles: ${hasRequiredRoles}`
    );

    return { isValid: true, hasRequiredRoles };
  } catch (error) {
    console.error("Error in verifyTokenAndGetUser:", error);
    return { isValid: false, hasRequiredRoles: false };
  }
};

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [hasRequiredRoles, setHasRequiredRoles] = useState(false);
  const { pathname } = useLocation();

  // Get token from localStorage and check if it exists
  const token = localStorage.getItem("token");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Verify token and get user information
  useEffect(() => {
    if (token) {
      verifyTokenAndGetUser(token).then(({ isValid, hasRequiredRoles }) => {
        setIsTokenValid(isValid);
        setHasRequiredRoles(hasRequiredRoles);
      });
    } else {
      setIsTokenValid(false);
      setHasRequiredRoles(false);
    }
  }, [token]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="#048404"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return isTokenValid && hasRequiredRoles ? (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <LoaderProvider>
          <RedirectProvider>
            <SnackbarProvider>
              {layout === "dashboard" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={
                      (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite
                    }
                    brandName="ELLE & LUI DASHBOARD"
                    routes={adminroutes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {configsButton}
                </>
              )}
              {layout === "vr" && <Configurator />}
              <Routes>
                {getRoutes(adminroutes)}
                {getRoutes(routes)}
                <Route path="/home" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/articles/:slug" element={<DetailledArticle />} />
                <Route path="/pages/authentication/reset-password" element={<ResetPassword />} />
                <Route path="/terms" element={<Terms />} />
              </Routes>
            </SnackbarProvider>
          </RedirectProvider>
        </LoaderProvider>
      </ThemeProvider>
    </>
  ) : (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <LoaderProvider>
          <RedirectProvider>
            <SnackbarProvider>
              <Routes>
                {getRoutes(routes)}
                <Route path="/home" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/articles/:slug" element={<DetailledArticle />} />
                <Route path="/pages/authentication/reset-password" element={<ResetPassword />} />
                <Route path="/terms" element={<Terms />} />
              </Routes>
              <Configurator />
              {configsButton}
            </SnackbarProvider>
          </RedirectProvider>
        </LoaderProvider>
      </ThemeProvider>
    </>
  );
}

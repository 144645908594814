import { useEffect } from "react";
import { useSnackbar } from "context/Snackbar";

const Logout = () => {
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    const handleLogout = () => {
      // Clear user session details from local storage
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("role");

      // Provide feedback to the user
      setSnackbar({ open: true, message: "Logged out successfully", color: "success" });

      // Reload the page to ensure session state is reset
      window.location.reload();
    };

    handleLogout();
  }, [setSnackbar]);

  return null; // This component does not render anything
};

export default Logout;

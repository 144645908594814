import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import GoogleIcon from "@mui/icons-material/Google";
import Checkbox from "@mui/material/Checkbox";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import MKInput from "components/User/MKInput";
import MKButton from "components/User/MKButton";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import SimpleFooter from "components/User/Footers/SimpleFooter";
import routes from "routes";
import bgImage from "assets/User/images/bg-sign-in-basic.jpeg";
import { loginUser } from "request/api";
import { useLoader } from "context/LoaderContext";
import { useRedirect } from "context/RedirectContext";
import { useSnackbar } from "context/Snackbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SignInBasic = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { setSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useLoader();
  const { redirectToPrevious } = useRedirect();
  const navigate = useNavigate();

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSignIn = async (e) => {
    e.preventDefault();
    showLoader();

    // Validate fields
    if (!identifier.trim() || !password.trim()) {
      setSnackbar({
        open: true,
        message: "Please fill all required fields.",
        color: "warning",
        date: new Date(),
      });
      hideLoader();
      return;
    }

    // Escape characters
    const escapeHtml = (text) => {
      const element = document.createElement("div");
      if (text) {
        element.innerText = text;
      }
      return element.innerHTML;
    };

    const escapedIdentifier = escapeHtml(identifier);
    const escapedPassword = escapeHtml(password);

    try {
      const data = await loginUser(escapedIdentifier, escapedPassword);

      if (data.token && String(data.message) === "Login successful") {
        // Check if the user's isVisible is true
        if (data.isVisible === false) {
          setSnackbar({
            open: true,
            message: "Your account is not currently active. Please contact support.",
            color: "error",
            date: new Date(),
          });

          // Remove any stored user information and token
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("email");
          localStorage.removeItem("role");

          hideLoader();
          return;
        }

        setSnackbar({
          open: true,
          message: "Login successful!",
          color: "success",
          date: new Date(),
        });

        // Save user information in localStorage
        localStorage.setItem("token", data.token);
        localStorage.setItem("username", data.username || "Unknown");
        localStorage.setItem("email", data.email || "Unknown");
        localStorage.setItem("role", data.roles.join(", ") || "Unknown");

        if (Array.isArray(data.roles) && data.roles.includes("admin")) {
          navigate("/dashboard");
        } else if (Array.isArray(data.roles) && data.roles.includes("user")) {
          redirectToPrevious();
        }
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      if (error.message === "Request failed with status code 401") {
        error.message = "Invalid credentials, please verify them.";
      }
      if (error.message === "Request failed with status code 404") {
        error.message = "User not found, please verify your credentials.";
      }
      setSnackbar({
        open: true,
        message: error.message,
        color: "error",
        date: new Date(),
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        transparent
        light
        sticky
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="120vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item mt={10} xs={12} sm={10} md={8} lg={6} xl={4}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
              >
                <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#fff" }}>ELLE & LUI</span> - Sign In
                </MKTypography>
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <FacebookIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <YoutubeIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <GoogleIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSignIn}>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Email or Username"
                      fullWidth
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MKBox>
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="success" type="submit" fullWidth>
                      Sign In
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/pages/authentication/register"
                        variant="button"
                        color="success"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign Up
                      </MKTypography>
                      <br></br>
                    </MKTypography>
                    <MKTypography variant="button" color="text">
                      Password forgotten?{" "}
                      <MKTypography
                        component={Link}
                        to="/pages/authentication/reset-password"
                        variant="button"
                        color="success"
                        fontWeight="medium"
                        textGradient
                      >
                        Reset password
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                  <MKBox
                    mt={3}
                    mb={1}
                    textAlign="center"
                    sx={{ position: "relative", display: "flex", justifyContent: "center" }}
                  >
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <MKBox
                        display="flex"
                        alignItems="center"
                        sx={{
                          position: "absolute",
                          left: "25%",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "text.primary",
                          "&:hover": {
                            color: "success.main",
                            "& .MuiSvgIcon-root": {
                              transform: "translateX(-5px)",
                            },
                          },
                          transition: "color 0.3s ease",
                        }}
                      >
                        <ArrowBackIcon
                          sx={{
                            fontSize: "2rem",
                            transition: "transform 0.3s ease",
                          }}
                        />
                        <MKTypography
                          variant="button"
                          fontWeight="medium"
                          color="text"
                          sx={{ ml: 1 }}
                        >
                          Return Home
                        </MKTypography>
                      </MKBox>
                    </Link>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={1} mt={5}>
        <SimpleFooter light />
      </MKBox>
    </>
  );
};

export default SignInBasic;

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// ELLE ET LUI -EGALITE React pages
import ResetPassword from "pages/User/LandingPages/ResetPassword";

export default function ResetPasswordPage() {
  return <ResetPassword />;
}

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// ELLE ET LUI -EGALITE React pages
import Logout from "pages/User/LandingPages/Logout";

export default function LogoutPage() {
  return <Logout />;
}

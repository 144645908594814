/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// ELLE ET LUI-EGALITE React components
import MDBox from "components/Admin/MDBox";
import MDTypography from "components/Admin/MDTypography";

// custom styles for the NotificationItem
import menuItem from "components/Admin/Items/NotificationItem/styles";

const NotificationItem = forwardRef(({ icon, title, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        {icon}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
        {title}
      </MDTypography>
    </MDBox>
  </MenuItem>
));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationItem;

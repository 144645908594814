import React from "react";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import { Card, Grid } from "@mui/material";

const ProgramsImage =
  "https://aleo-avocats.fr/wp-content/uploads/2023/11/istockphoto-1209210322-612x612.jpg";

const programs = [
  {
    number: "01",
    title: "Personal Development and Leadership Pole",
    description:
      "Enhance your personal skills and leadership abilities through workshops and training. This program focuses on building self-awareness, improving decision-making, and developing effective leadership strategies to empower you personally and professionally.",
  },
  {
    number: "02",
    title: "Gender Equality and Empowerment Pole",
    description:
      "Address critical issues of gender inequality and promote inclusivity. This program offers interactive workshops, educational seminars, and practical advocacy skills training to challenge biases and support gender equity. Mentorship opportunities with leaders and hands-on projects aim to create tangible change in communities and workplaces.",
  },
  {
    number: "03",
    title: "Community and Social Pole",
    description:
      "Engage in community initiatives to drive positive change. Activities include volunteer work, community organizing, and social advocacy to strengthen bonds and address local issues effectively.",
  },
  {
    number: "04",
    title: "Communication, Partnership and Mentoring Pole",
    description:
      "Develop communication skills and build strategic partnerships through mentorship and collaborative projects. Focus on enhancing interpersonal skills, fostering professional networks, and guiding individuals through mentorship to achieve their goals.",
  },
];

function Programs() {
  return (
    <>
      <MKBox
        px={2}
        width="100%"
        mx="auto"
        position="relative"
        zIndex={2}
        sx={{
          padding: { xs: 1, sm: 2, md: 3 },
          margin: { xs: 1, sm: 2, md: 3 },
          "@media (max-width:auto)": {
            padding: 1,
            margin: 1,
          },
        }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12}>
            <MKBox pt={4} pb={3}>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <MKBox
                    component="img"
                    src={ProgramsImage}
                    alt="Programs"
                    width="100%"
                    borderRadius="md"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKTypography variant="h3" fontWeight="bold" mb={2}>
                    <span style={{ color: " #048404" }}>Programs</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Discover the <strong>programs</strong> we offer to help young people develop,
                    acquire new skills, and become actively engaged in their community.
                  </MKTypography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                {programs.map((program, index) => (
                  <Grid item p={1} xs={12} sm={6} md={6} lg={6} key={index}>
                    <MKBox
                      component={Card}
                      sx={{
                        transition: "transform 0.3s, box-shadow 0.3s",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 6px 18px grey",
                        },
                      }}
                    >
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        mb={2}
                      >
                        <MKTypography variant="h4" fontWeight="bold" style={{ color: "#048404" }}>
                          {program.number}
                        </MKTypography>
                      </MKBox>
                      <MKTypography variant="h5" fontWeight="bold" gutterBottom>
                        {program.title}
                      </MKTypography>
                      <MKTypography
                        variant="body1"
                        color="text"
                        style={{
                          hyphens: "auto",
                        }}
                      >
                        {program.description}
                      </MKTypography>
                    </MKBox>
                  </Grid>
                ))}
              </Grid>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default Programs;

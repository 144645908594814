/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

// ELLE ET LUI-EGALITE React base styles
import borders from "assets/Admin/theme-dark/base/borders";
import colors from "assets/Admin/theme-dark/base/colors";

// ELLE ET LUI-EGALITE React helper functions
import pxToRem from "assets/Admin/theme-dark/functions/pxToRem";

const { borderWidth } = borders;
const { light } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    },
  },
};

export default tableCell;

import React from "react";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import { Card, Grid, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import routes from "routes";
import footerRoutes from "footer.routes";

const token = localStorage.getItem("token");
const isTokenValid = token ? true : false;

const JoinUsImage = "https://spaceinafrica.com/wp-content/uploads/2019/01/Get-Involved.jpg";
const JoinUs = [
  {
    number: "01",
    title: "Get Involved",
    description:
      "Join 'Elle & Lui' and contribute to our mission through various action areas. By participating in our initiatives, you will have the opportunity to make a meaningful impact in areas such as gender equality, community development, and social advocacy. Our program encourages active involvement from individuals who are passionate about making a difference and provides numerous ways to engage, including volunteering, participating in events, and supporting campaigns. Your contribution will help drive our mission forward and foster a more inclusive and equitable society.",
  },
  {
    number: "02",
    title: "Join Us",
    description:
      "Discover how you can get involved as a volunteer, supporter, or active member of our community. This program offers various avenues for participation, from hands-on volunteering opportunities to becoming a key supporter of our initiatives. We welcome individuals who are eager to lend their time, skills, and resources to help advance our cause. Whether you want to participate in community events, assist with program implementation, or engage in advocacy efforts, your involvement will play a crucial role in achieving our goals and creating a positive impact.",
  },
  {
    number: "03",
    title: "Become a Partner",
    description:
      "Explore partnership opportunities with our organization to support our actions and JoinUs. By becoming a partner, you will collaborate with us to enhance our impact and reach. We offer various partnership models, including sponsorships, corporate collaborations, and strategic alliances. Partners will benefit from increased visibility, recognition, and the satisfaction of contributing to meaningful change. Join us in our mission to promote gender equality and support our JoinUs through strategic and impactful partnerships.",
  },
  {
    number: "04",
    title: "Become a Mentor",
    description:
      "Consider becoming a mentor to guide and inspire young people towards a promising future. Our mentorship program pairs experienced individuals with youth seeking guidance and support. As a mentor, you will provide valuable advice, share your experiences, and help mentees navigate their personal and professional journeys. This role not only supports the development of future leaders but also enriches your own experience and contributes to the broader mission of empowering individuals. Your mentorship will make a significant difference in shaping the next generation's success and confidence.",
  },
];

function Involve() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/authentication/register",
          label: "Donate",
          color: "success",
        }}
        sticky
      />
      <MKBox
        px={2}
        width="100%"
        mx="auto"
        position="relative"
        zIndex={2}
        sx={{
          padding: { xs: 1, sm: 2, md: 3 },
          margin: { xs: 1, sm: 2, md: 3 },
          "@media (max-width:md)": {
            padding: 2,
            margin: 2,
          },
        }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} mt="20vh">
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="md"
                coloredShadow="dark"
                mx={2}
                mt={-3}
                p={2}
                mb={3}
              >
                <MKTypography variant="h2" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#FF5206" }}>ELLE & LUI</span> - Getting Involved
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox
                  sx={{
                    display: "block",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: 2,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <MKBox
                        component="img"
                        src={JoinUsImage}
                        alt="JoinUs"
                        width="100%"
                        borderRadius="md"
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <MKTypography variant="h3" fontWeight="bold" mb={2}>
                        <span style={{ color: " #048404" }}>Getting Involved</span>
                      </MKTypography>
                      <MKTypography variant="body1" color="text">
                        Get involved with <strong>“Elle & Lui”</strong> and contribute to our
                        mission through different areas of action.
                      </MKTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={4}>
                    {JoinUs.map((program, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <MKBox
                          component={Card}
                          sx={{
                            transition: "transform 0.3s, box-shadow 0.3s",
                            "&:hover": {
                              transform: "translateY(-10px)",
                              boxShadow: "0 6px 18px rgba(255, 82, 6, 0.5)",
                            },
                          }}
                        >
                          <CardContent>
                            <MKBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              mb={2}
                            >
                              <MKTypography
                                variant="h4"
                                fontWeight="bold"
                                style={{ color: "#048404" }}
                              >
                                {program.number}
                              </MKTypography>
                            </MKBox>
                            <MKTypography
                              variant="h5"
                              fontWeight="bold"
                              gutterBottom
                              textAlign="left"
                            >
                              {program.title}
                            </MKTypography>
                            <MKTypography
                              variant="body1"
                              color="text"
                              style={{
                                hyphens: "auto",
                                textAlign: "justify",
                              }}
                            >
                              {program.description}
                            </MKTypography>
                          </CardContent>
                        </MKBox>
                      </Grid>
                    ))}
                  </Grid>
                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <MKBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        mt: 5,
                        left: "4%",
                        transform: "translateY(-60%)",
                        color: "text.primary",
                        "&:hover": {
                          color: "info.main",
                          "& .MuiSvgIcon-root": {
                            transform: "translateX(-7px)",
                          },
                        },
                        transition: "color 0.3s ease",
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          fontSize: "3rem",
                          transition: "transform 0.3s ease",
                        }}
                      />
                      <MKTypography variant="h5" fontWeight="bold" color="text" sx={{ ml: 1 }}>
                        Return Home
                      </MKTypography>
                    </MKBox>
                  </Link>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>

      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default Involve;

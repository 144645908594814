import { useState, useEffect } from "react";

const useElapsedTime = (startTime) => {
  const [elapsedTime, setElapsedTime] = useState("0s ago");

  useEffect(() => {
    if (!startTime) return;

    const updateElapsedTime = () => {
      const now = new Date();
      const diff = Math.floor((now - startTime) / 1000);
      setElapsedTime(` ${diff} ${diff === 1 ? "second" : "seconds"} ago`);
    };

    updateElapsedTime(); // Initial call
    const interval = setInterval(updateElapsedTime, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  return elapsedTime;
};

export default useElapsedTime;

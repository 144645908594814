/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MDBox from "components/Admin/MDBox";
import DashboardLayout from "components/Admin/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Admin/Navbars/DashboardNavbar";
import Footer from "components/Admin/Footer";
import ComplexStatisticsCard from "components/Admin/Cards/StatisticsCards/ComplexStatisticsCard";
import { getUsers, getArticles, getComments, getSubscriptions, getMediaFiles } from "request/api";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";

function Dashboard() {
  const [userCount, setUserCount] = useState(0);
  const [articleCount, setArticleCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [mediaCount, setMediaCount] = useState(0); // New state for media count

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await getUsers();
        const articlesResponse = await getArticles();
        const commentsResponse = await getComments();
        const subscriptionsResponse = await getSubscriptions();
        const mediaResponse = await getMediaFiles(); // Fetch media files

        setUserCount(usersResponse.length);
        setArticleCount(articlesResponse.length);
        setCommentCount(commentsResponse.length);
        setSubscriptionCount(subscriptionsResponse.length);
        setMediaCount(mediaResponse.length); // Set media count
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="person"
                title="Number of Users"
                count={userCount}
                percentage={{
                  color: "success",
                  label: (
                    <MKBox mt={1} textAlign="center">
                      <Link to="/dashboard/users" style={{ textDecoration: "none" }}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          sx={{
                            justifyContent: "center",
                            color: "text.primary",
                            "&:hover": {
                              color: "success.main",
                              "& .MuiSvgIcon-root": {
                                transform: "translateX(-5px)",
                              },
                            },
                            transition: "color 0.3s ease",
                          }}
                        >
                          <ArrowBackIcon
                            sx={{
                              fontSize: "1.5rem",
                              transform: "rotate(180deg)",
                              transition: "transform 0.3s ease",
                            }}
                          />
                          <MKTypography
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            sx={{ ml: 1 }}
                          >
                            View Users
                          </MKTypography>
                        </MKBox>
                      </Link>
                    </MKBox>
                  ),
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="article"
                title="Number of Articles"
                count={articleCount}
                percentage={{
                  color: "success",
                  label: (
                    <MKBox mt={1} textAlign="center">
                      <Link to="/dashboard/articles" style={{ textDecoration: "none" }}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          sx={{
                            justifyContent: "center",
                            color: "text.primary",
                            "&:hover": {
                              color: "success.main",
                              "& .MuiSvgIcon-root": {
                                transform: "translateX(-5px)",
                              },
                            },
                            transition: "color 0.3s ease",
                          }}
                        >
                          <ArrowBackIcon
                            sx={{
                              fontSize: "1.5rem",
                              transform: "rotate(180deg)",
                              transition: "transform 0.3s ease",
                            }}
                          />
                          <MKTypography
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            sx={{ ml: 1 }}
                          >
                            View Articles
                          </MKTypography>
                        </MKBox>
                      </Link>
                    </MKBox>
                  ),
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="comment"
                title="Number of Comments"
                count={commentCount}
                percentage={{
                  color: "success",
                  label: "Go to articles to see them",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="subscriptions"
                title="Number of Subscriptions"
                count={subscriptionCount}
                percentage={{
                  color: "success",
                  label: (
                    <MKBox mt={1} textAlign="center">
                      <Link to="/subscriptions" style={{ textDecoration: "none" }}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          sx={{
                            justifyContent: "center",
                            color: "text.primary",
                            "&:hover": {
                              color: "success.main",
                              "& .MuiSvgIcon-root": {
                                transform: "translateX(-5px)",
                              },
                            },
                            transition: "color 0.3s ease",
                          }}
                        >
                          <ArrowBackIcon
                            sx={{
                              fontSize: "1.5rem",
                              transform: "rotate(180deg)",
                              transition: "transform 0.3s ease",
                            }}
                          />
                          <MKTypography
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            sx={{ ml: 1 }}
                          >
                            View Subscriptions
                          </MKTypography>
                        </MKBox>
                      </Link>
                    </MKBox>
                  ),
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="perm_media"
                title="Number of Media Files"
                count={mediaCount}
                percentage={{
                  color: "success",
                  label: (
                    <MKBox mt={1} textAlign="center">
                      <Link to="/dashboard/media-control" style={{ textDecoration: "none" }}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          sx={{
                            justifyContent: "center",
                            color: "text.primary",
                            "&:hover": {
                              color: "success.main",
                              "& .MuiSvgIcon-root": {
                                transform: "translateX(-5px)",
                              },
                            },
                            transition: "color 0.3s ease",
                          }}
                        >
                          <ArrowBackIcon
                            sx={{
                              fontSize: "1.5rem",
                              transform: "rotate(180deg)",
                              transition: "transform 0.3s ease",
                            }}
                          />
                          <MKTypography
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            sx={{ ml: 1 }}
                          >
                            View Articles
                          </MKTypography>
                        </MKBox>
                      </Link>
                    </MKBox>
                  ),
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

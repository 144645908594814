/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/Admin/MDBox";
import MKButton from "components/User/MKButton";
import MDTypography from "components/Admin/MDTypography";
import DashboardLayout from "components/Admin/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Admin/Navbars/DashboardNavbar";
import Footer from "components/Admin/Footer";
import DataTable from "components/Admin/Tables/DataTable";

import ArticlesTableData from "layouts/Admin/Articles/data/ArticlesTableData";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createArticle, updateArticle } from "request/api"; // Ensure this path is correct
import { useSnackbar } from "context/Snackbar";
import { jwtDecode } from "jwt-decode";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

function Articles() {
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [error, setError] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);

  const handleClickOpen = () => {
    setIsEditMode(false);
    setOpen(true);
  };

  const handleEditOpen = (article) => {
    setIsEditMode(true);
    setCurrentArticleId(article._id);
    setTitle(article.title);
    setContent(article.content);
    setCategory(article.category);
    setTags(article.tags.join(", "));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setContent("");
    setCategory("");
    setTags("");
    setError("");
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      const articleData = {
        title,
        content,
        category,
        tags: tags.split(",").map((tag) => tag.trim()),
        author: userId,
      };

      if (isEditMode && currentArticleId) {
        await updateArticle(currentArticleId, articleData);
        setSnackbar({
          open: true,
          message: "Article updated successfully!",
          color: "success",
        });
      } else {
        await createArticle(articleData);

        setSnackbar({
          open: true,
          message: "Article created successfully!",
          color: "success",
        });
      }

      handleClose();
    } catch (error) {
      console.error("Error saving article:", error);
      setSnackbar({
        open: true,
        message: error.message,
        color: "error",
      });
    }
  };

  const { columns, rows } = ArticlesTableData({ onEdit: handleEditOpen });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Articles Table
                </MDTypography>
                <Grid container justifyContent="flex-end">
                  <MKButton
                    variant="contained"
                    color="white"
                    startIcon={<AddIcon />}
                    onClick={handleClickOpen}
                  >
                    Create Article
                  </MKButton>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{isEditMode ? "Edit Article" : "Create Article"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enter the Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            required
          />
          <TextField
            margin="dense"
            label="Enter the Category"
            type="text"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            variant="outlined"
            required
          />
          <TextField
            margin="dense"
            label="Enter the Tags (separated by commas)"
            type="text"
            fullWidth
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            variant="outlined"
          />
          <ReactQuill theme="snow" modules={modules} value={content} onChange={setContent} />
          {error && <p style={{ color: "red" }}>{error}</p>}
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose} color="error">
            Cancel
          </MKButton>
          <MKButton onClick={handleSave} color="success">
            {isEditMode ? "Update" : "Save"}
          </MKButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Articles;

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, TextField, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getUsers, updateUser } from "request/api"; // Utiliser les fonctions fournies
import { useSnackbar } from "context/Snackbar";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import SimpleFooter from "components/User/Footers/SimpleFooter";
import bgImage from "assets/User/images/bg-sign-in-basic.jpeg";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import MKButton from "components/User/MKButton";
import routes from "routes";

import PropTypes from "prop-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bcrypt from "bcryptjs";
import emailjs from "emailjs-com"; // Importer EmailJS

// Fonction pour générer un code de réinitialisation aléatoire
const generateResetCode = () => {
  return Math.floor(100000 + Math.random() * 900000).toString(); // Génère un code à 6 chiffres
};

// Fonction pour envoyer l'email avec le code de réinitialisation
const sendResetCodeEmail = async (email, resetCode, username) => {
  const templateParams = {
    to_email: email,
    reset_code: resetCode,
    username: username,
  };

  try {
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, // Utiliser la variable d'environnement pour le service ID
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID_RESET, // Utiliser la variable d'environnement pour le template ID
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID // Utiliser la variable d'environnement pour le user ID
    );
    return true;
  } catch (error) {
    console.error("Échec de l'envoi de l'email :", error);
    return false;
  }
};

const ProgressBar = ({ value }) => (
  <Box
    sx={{
      width: "100%",
      height: 10,
      backgroundColor: "#ccc",
      borderRadius: 5,
      overflow: "hidden",
      mt: 2,
    }}
  >
    <Box
      sx={{
        width: `${value}%`,
        height: "100%",
        backgroundColor: "#4caf50",
        transition: "width 0.3s ease",
      }}
    />
  </Box>
);

function PasswordReset() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState(""); // État pour l'ID de l'utilisateur
  const [generatedCode, setGeneratedCode] = useState(""); // État pour le code généré
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setProgress(33);

    if (!email.trim()) {
      setSnackbar({
        open: true,
        message: "Veuillez entrer votre adresse email.",
        color: "warning",
      });
      setLoading(false);
      return;
    }

    try {
      const users = await getUsers();
      const user = users.find((user) => user.email === email);
      if (!user) {
        setSnackbar({
          open: true,
          message: "Email not found.",
          color: "error",
        });
        setLoading(false);
        return;
      }

      // Définir l'ID de l'utilisateur et le nom d'utilisateur
      setUserId(user._id);
      const username = user.username; // Stocker le nom d'utilisateur dans la variable 'username'

      // Générer et envoyer le code (vérifier le code réel en production)
      const resetCode = generateResetCode();
      const emailSent = await sendResetCodeEmail(email, resetCode, username);

      if (!emailSent) {
        setSnackbar({
          open: true,
          message: "Failed to send reset code.",
          color: "error",
        });
        setLoading(false);
        return;
      }

      setGeneratedCode(resetCode); // Stocker le code généré pour la vérification
      setSnackbar({
        open: true,
        message: "The reset code has been sent to your email!",
        color: "success",
      });
      setStep(2);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Échec de la vérification de votre email, veuillez réessayer.",
        color: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCodeVerification = async (event) => {
    event.preventDefault();
    setLoading(true);
    setProgress(66);

    if (code !== generatedCode) {
      setSnackbar({
        open: true,
        message: "Code invalide.",
        color: "error",
      });
      setLoading(false);
      return;
    }

    setSnackbar({
      open: true,
      message: "Code vérifié. Vous pouvez maintenant réinitialiser votre mot de passe.",
      color: "success",
    });
    setStep(3);
    setProgress(100);
    setLoading(false);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setSnackbar({
        open: true,
        message: "Les mots de passe ne correspondent pas.",
        color: "error",
      });
      setLoading(false);
      return;
    }

    try {
      if (!userId) {
        throw new Error("L'ID utilisateur n'est pas disponible.");
      }
      // Hacher le mot de passe sur le frontend
      const hashedPassword = await bcrypt.hash(newPassword, 10);
      await updateUser(userId, { password: hashedPassword });

      setSnackbar({
        open: true,
        message: "Mot de passe mis à jour avec succès !",
        color: "success",
      });
      navigate("/pages/authentication/sign-in");
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        color: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
      >
        <CircularProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="120vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item mt={10} xs={12} sm={10} md={8} lg={6} xl={4}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
              >
                <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#fff" }}>ELLE & LUI</span> - Réinitialisation du mot de
                  passe
                </MKTypography>
                <MKTypography variant="button" color="white" mt={1}>
                  Mot de passe oublié ? Pas de souci, vous pouvez le réinitialiser
                </MKTypography>
              </MKBox>
              <MKBox pt={2} pb={3} px={3}>
                <ProgressBar value={progress} />
              </MKBox>
              <MKBox pb={3} px={3}>
                {step === 1 && (
                  <MKBox component="form" role="form" onSubmit={handleEmailSubmit}>
                    <MKTypography variant="h6" fontWeight="normal" mb={3}>
                      Entrez votre email
                    </MKTypography>
                    <MKBox mb={2}>
                      <TextField
                        type="email"
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </MKBox>
                    <MKButton variant="gradient" color="success" type="submit" fullWidth>
                      Envoyer le code de réinitialisation
                    </MKButton>
                  </MKBox>
                )}
                {step === 2 && (
                  <MKBox component="form" role="form" onSubmit={handleCodeVerification}>
                    <MKTypography variant="h6" fontWeight="normal" mb={3}>
                      Nous avons envoyé un email avec le code de réinitialisation, veuillez vérifier
                      votre email et coller le code envoyé ici
                    </MKTypography>
                    <MKBox mb={2}>
                      <TextField
                        type="text"
                        label="Code de réinitialisation"
                        fullWidth
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </MKBox>
                    <MKButton variant="gradient" color="success" type="submit" fullWidth>
                      Vérifier le code
                    </MKButton>
                  </MKBox>
                )}
                {step === 3 && (
                  <MKBox component="form" role="form" onSubmit={handlePasswordReset}>
                    <MKBox mb={2}>
                      <MKTypography variant="h6" fontWeight="normal" mb={3}>
                        Entrez un nouveau mot de passe et confirmez-le
                      </MKTypography>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        label="Nouveau mot de passe"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleShowPassword}
                                edge="end"
                                aria-label="toggle password visibility"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        label="Confirmez le mot de passe"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleShowPassword}
                                edge="end"
                                aria-label="toggle password visibility"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MKBox>
                    <MKButton variant="gradient" color="success" type="submit" fullWidth>
                      Réinitialiser le mot de passe
                    </MKButton>
                  </MKBox>
                )}
              </MKBox>
              <MKBox
                mt={3}
                mb={5}
                textAlign="center"
                sx={{ position: "relative", display: "flex", justifyContent: "center" }}
              >
                <Link to="/pages/authentication/sign-in" style={{ textDecoration: "none" }}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      left: "25%",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "text.primary",
                      "&:hover": {
                        color: "success.main",
                        "& .MuiSvgIcon-root": {
                          transform: "translateX(-5px)",
                        },
                      },
                      transition: "color 0.3s ease",
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        fontSize: "2rem",
                        transition: "transform 0.3s ease",
                      }}
                    />
                    <MKTypography variant="button" fontWeight="medium" color="text" sx={{ ml: 1 }}>
                      Retour à la page de connexion
                    </MKTypography>
                  </MKBox>
                </Link>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={1} mt={5}>
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default PasswordReset;

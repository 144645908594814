/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// @mui material componentsimport React from 'react';

import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Card } from "@mui/material";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import routes from "routes";
import footerRoutes from "footer.routes";

const token = localStorage.getItem("token");
const isTokenValid = token ? true : false;
const News = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/authentication/register",
          label: "Donate",
          color: "success",
        }}
        sticky
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} mt="20vh">
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="md"
                coloredShadow="dark"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
              >
                <MKTypography variant="h2" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#FF5206" }}>ELLE & LUI</span> - News
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <Box
                  sx={{
                    display: "block",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: 2,
                  }}
                >
                  <Typography variant="h3" color="text.secondary">
                    This page is not yet available, we&apos;ll update it very soon.
                  </Typography>
                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <MKBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        mt: 5,
                        left: "4%",
                        transform: "translateY(-60%)",
                        color: "text.primary",
                        "&:hover": {
                          color: "info.main",
                          "& .MuiSvgIcon-root": {
                            transform: "translateX(-7px)",
                          },
                        },
                        transition: "color 0.3s ease",
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          fontSize: "3rem",
                          transition: "transform 0.3s ease",
                        }}
                      />
                      <MKTypography variant="h5" fontWeight="bold" color="text" sx={{ ml: 1 }}>
                        Return Home
                      </MKTypography>
                    </MKBox>
                  </Link>
                </Box>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>

      <DefaultFooter content={footerRoutes} />
    </>
  );
};

export default News;

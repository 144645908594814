/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// ELLE ET LUI -EGALITE React pages
import SignIn from "pages/User/LandingPages/SignIn";

export default function SignInPage() {
  return <SignIn />;
}

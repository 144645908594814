/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

// ELLE ET LUI-EGALITE React components
import MDBox from "components/Admin/MDBox";
import MDTypography from "components/Admin/MDTypography";
import MKButton from "components/User/MKButton";

// ELLE ET LUI-EGALITE React example components
import DashboardLayout from "components/Admin/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Admin/Navbars/DashboardNavbar";
import Footer from "components/Admin/Footer";
import DataTable from "components/Admin/Tables/DataTable";

// Data
import UsersTableData from "layouts/Admin/users/data/UsersTableData";
import AdminTableData from "layouts/Admin/users/data/AdminTableData";
import { updateUser } from "request/api"; // Ensure this function is implemented
import { useSnackbar } from "context/Snackbar";

function Users() {
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [error, setError] = useState("");

  const handleEditOpen = (user) => {
    setCurrentUser(user);
    setUsername(user.username);
    setEmail(user.email);
    setIsVisible(user.isVisible);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser(null);
    setUsername("");
    setEmail("");
    setIsVisible(true);
    setError("");
  };

  const handleSave = async () => {
    try {
      if (currentUser) {
        const updatedUser = {
          username,
          email,
          isVisible,
        };
        await updateUser(currentUser._id, updatedUser);
        setSnackbar({
          open: true,
          message: "User updated successfully!",
          color: "success",
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setSnackbar({
        open: true,
        message: "Failed to update user. Please try again.",
        color: "error",
      });
    }
  };

  const { columns, rows } = UsersTableData({ onEdit: handleEditOpen });
  const { columns: pColumns, rows: pRows } = AdminTableData({ onEdit: handleEditOpen });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Users Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Admin Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
            required
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            required
          />
          <MDBox mt={2} display="flex" alignItems="center">
            <MDTypography variant="subtitle1" mr={2}>
              Status:
            </MDTypography>
            <Switch
              checked={isVisible}
              onChange={(e) => setIsVisible(e.target.checked)}
              color="primary"
            />
            <MDTypography variant="body2" ml={2}>
              {isVisible ? "Active" : "Inactive"}
            </MDTypography>
          </MDBox>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose} color="error">
            Cancel
          </MKButton>
          <MKButton onClick={handleSave} color="success">
            Save
          </MKButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Users;

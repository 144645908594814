/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

import axios from "axios";

// Create an Axios instance with default settings
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Your backend URL
  timeout: 100000, // Timeout after 10 seconds
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the token in headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to send error logs to backend
const logErrorToBackend = async (error) => {
  try {
    console.log("Logging error to backend:", error); // Log the error being sent
    const response = await api.post("/log-error", {
      error: { message: error.message, stack: error.stack },
    });
    console.log("Error logged successfully:", response.data);
  } catch (logError) {
    console.error("Failed to log error to backend:", logError);
  }
};

// Function to create a new user
export const createUser = async (userData) => {
  try {
    const response = await api.post("/users", userData);
    return response.data;
  } catch (error) {
    console.error("Error in createUser:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error; // Re-throw to handle in UI
  }
};

// Function to get users
export const getUsers = async () => {
  try {
    const response = await api.get("/users");
    return response.data;
  } catch (error) {
    console.error("Error in getUsers:", error);
    throw error;
  }
};

// Function to update user information
export const updateUser = async (userId, updatedData) => {
  try {
    // Send a PUT request to update the user
    const response = await api.put(`/users/${userId}`, updatedData);

    // Check if the response data is what you expect
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error("Failed to update user");
    }
  } catch (error) {
    // Log error to console and backend
    console.error("Error in updateUser:", error);

    // Log error details to the backend (assuming logErrorToBackend function exists)
    try {
      await logErrorToBackend(error);
    } catch (loggingError) {
      console.error("Error logging to backend:", loggingError);
    }

    // Re-throw error to handle in UI
    throw error;
  }
};

// Function to login
export const loginUser = async (identifier, password) => {
  try {
    const credentials = { identifier, password };
    const response = await api.post("/auth/signin", JSON.stringify(credentials), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response.data); // Vérifiez la structure ici
    return response.data;
  } catch (error) {
    console.error("Error in loginUser:", error);
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to get a list of articles
export const getArticles = async () => {
  try {
    const response = await api.get("/articles");
    return response.data;
  } catch (error) {
    console.error("Error in getArticles:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to get a single article by ID
export const getArticleById = async (articleId) => {
  try {
    const response = await api.get(`/articles/${articleId}`);
    return response.data;
  } catch (error) {
    console.error("Error in getArticleById:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to create a new article
export const createArticle = async (articleData) => {
  try {
    const response = await api.post("/articles", articleData);
    return response.data;
  } catch (error) {
    console.error("Error in createArticle:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to update an article by ID
export const updateArticle = async (articleId, articleData) => {
  try {
    const response = await api.put(`/articles/${articleId}`, articleData);
    return response.data;
  } catch (error) {
    console.error("Error in updateArticle:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to delete an article by ID
export const deleteArticle = async (articleId) => {
  try {
    const response = await api.delete(`/articles/${articleId}`);
    return response.data;
  } catch (error) {
    console.error("Error in deleteArticle:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to get all comments and optionally filter by articleId
export const getComments = async (articleId) => {
  try {
    const response = await api.get("/comments"); // Fetch all comments from the backend
    if (articleId) {
      // Filter comments by articleId on the client side if articleId is provided
      return response.data.filter((comment) => comment.article.toString() === articleId);
    }
    return response.data; // Return all comments if no articleId is provided
  } catch (error) {
    console.error("Error in getComments:", error); // Log the error before sending to backend
    await logErrorToBackend(error); // Log error to backend for further analysis
    throw error; // Re-throw error for further handling
  }
};

// Function to add a comment to an article
export const addComment = async (commentData) => {
  try {
    const response = await api.post("/comments", commentData);
    return response.data;
  } catch (error) {
    console.error("Error in addComment:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to delete a comment by ID
export const deleteComment = async (articleId, commentId) => {
  try {
    const response = await api.delete(`/articles/${articleId}/comments/${commentId}`);
    return response.data;
  } catch (error) {
    console.error("Error in deleteComment:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to get user activities
export const getUserActivities = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}/activities`);
    return response.data;
  } catch (error) {
    console.error("Error in getUserActivities:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to create a new activity
export const createActivity = async (userId, activityData) => {
  try {
    const response = await api.post(`/users/${userId}/activities`, activityData);
    return response.data;
  } catch (error) {
    console.error("Error in createActivity:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to get subscriptions
export const getSubscriptions = async () => {
  try {
    const response = await api.get("/subscriptions");
    return response.data;
  } catch (error) {
    console.error("Error in getSubscriptions:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to add a subscription
export const addSubscription = async (subscriptionData) => {
  try {
    const response = await api.post("/subscriptions", subscriptionData);
    return response.data;
  } catch (error) {
    console.error("Error in addSubscription:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to delete a subscription by ID
export const deleteSubscription = async (subscriptionId) => {
  try {
    const response = await api.delete(`/subscriptions/${subscriptionId}`);
    return response.data;
  } catch (error) {
    console.error("Error in deleteSubscription:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to get media files
export const getMediaFiles = async () => {
  try {
    const response = await api.get("/media");
    return response.data;
  } catch (error) {
    console.error("Error in getMediaFiles:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to upload a new media file
export const uploadMediaFile = async (mediaData) => {
  try {
    const response = await api.post("/media", mediaData);
    return response.data;
  } catch (error) {
    console.error("Error in uploadMediaFile:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

// Function to delete a media file by ID
export const deleteMediaFile = async (mediaId) => {
  try {
    const response = await api.delete(`/media/${mediaId}`);
    return response.data;
  } catch (error) {
    console.error("Error in deleteMediaFile:", error); // Log the error before sending to backend
    await logErrorToBackend(error);
    throw error;
  }
};

export default api;

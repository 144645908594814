import React from "react";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import { Card, Grid, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import routes from "routes";
import footerRoutes from "footer.routes";

const token = localStorage.getItem("token");
const isTokenValid = token ? true : false;

const ResourceImage =
  "https://media.istockphoto.com/id/1181567551/photo/resources-word-in-wooden-cube.jpg?s=612x612&w=0&k=20&c=TrtPw45mdrtkbEvx24SVlPdOiSKMfWqzvB0ka_YP1Wc=";
const Resource = [
  {
    number: "01",
    title: "Explore Resources",
    description:
      "Dive into our diverse range of resources designed to support and enhance your knowledge and involvement. Our collection includes educational materials, research reports, and practical guides to help you understand and engage with our mission effectively. By utilizing these resources, you will be better equipped to contribute to our cause and stay informed about our initiatives.",
  },
  {
    number: "02",
    title: "Access Tools and Guides",
    description:
      "Gain access to a variety of tools and guides that will assist you in your journey with 'Elle & Lui.' These resources include step-by-step instructions, templates, and best practices for getting involved, whether through volunteering, advocacy, or community support. Our aim is to provide you with everything you need to make a positive impact and actively participate in our mission.",
  },
  {
    number: "03",
    title: "Connect with Our Network",
    description:
      "Explore opportunities to connect with our extensive network of supporters, volunteers, and partners. Access directories, join forums, and participate in events designed to foster collaboration and engagement. Connecting with our network will help you build relationships, share insights, and collaborate on initiatives that drive our mission forward.",
  },
  {
    number: "04",
    title: "Get Involved",
    description:
      "Find out how you can get directly involved with 'Elle & Lui' through various engagement opportunities. From attending events to participating in campaigns, our resources provide information on how to actively contribute and make a difference. Learn more about our current initiatives and how you can play a role in advancing our mission.",
  },
];

function Resources() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/authentication/register",
          label: "Donate",
          color: "success",
        }}
        sticky
      />
      <MKBox
        px={2}
        width="100%"
        mx="auto"
        position="relative"
        zIndex={2}
        sx={{
          padding: { xs: 1, sm: 2, md: 3 },
          margin: { xs: 1, sm: 2, md: 3 },
          "@media (max-width:md)": {
            padding: 2,
            margin: 2,
          },
        }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} mt="20vh">
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="md"
                coloredShadow="dark"
                mx={2}
                mt={-3}
                p={2}
                mb={3}
              >
                <MKTypography variant="h2" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#FF5206" }}>ELLE & LUI</span> - Resources
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox
                  sx={{
                    display: "block",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: 2,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <MKBox
                        component="img"
                        src={ResourceImage}
                        alt="Resource"
                        width="100%"
                        borderRadius="md"
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <MKTypography variant="h3" fontWeight="bold" mb={2}>
                        <span style={{ color: " #048404" }}>All the Resources</span>
                      </MKTypography>
                      <MKTypography variant="body1" color="text">
                        Explore the <strong>“Elle & Lui”</strong> resources and discover how you can
                        support our mission through a variety of avenues. Our resources provide
                        valuable insights, tools, and information to help you engage effectively.
                        Whether you&apos;e looking to volunteer, partner with us, or simply learn
                        more about our initiatives, our resources are designed to empower and guide
                        you in making a meaningful impact. Join us in our commitment to fostering
                        change and driving positive outcomes in our community.
                      </MKTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={4}>
                    {Resource.map((program, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <MKBox
                          component={Card}
                          sx={{
                            transition: "transform 0.3s, box-shadow 0.3s",
                            "&:hover": {
                              transform: "translateY(-10px)",
                              boxShadow: "0 6px 18px rgba(255, 82, 6, 0.5)",
                            },
                          }}
                        >
                          <CardContent>
                            <MKBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              mb={2}
                            >
                              <MKTypography
                                variant="h4"
                                fontWeight="bold"
                                style={{ color: "#048404" }}
                              >
                                {program.number}
                              </MKTypography>
                            </MKBox>
                            <MKTypography
                              variant="h5"
                              fontWeight="bold"
                              gutterBottom
                              textAlign="left"
                            >
                              {program.title}
                            </MKTypography>
                            <MKTypography
                              variant="body1"
                              color="text"
                              style={{
                                hyphens: "auto",
                                textAlign: "justify",
                              }}
                            >
                              {program.description}
                            </MKTypography>
                          </CardContent>
                        </MKBox>
                      </Grid>
                    ))}
                  </Grid>
                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <MKBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        mt: 5,
                        left: "4%",
                        transform: "translateY(-60%)",
                        color: "text.primary",
                        "&:hover": {
                          color: "info.main",
                          "& .MuiSvgIcon-root": {
                            transform: "translateX(-7px)",
                          },
                        },
                        transition: "color 0.3s ease",
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          fontSize: "3rem",
                          transition: "transform 0.3s ease",
                        }}
                      />
                      <MKTypography variant="h5" fontWeight="bold" color="text" sx={{ ml: 1 }}>
                        Return Home
                      </MKTypography>
                    </MKBox>
                  </Link>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>

      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default Resources;

/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import MKButton from "components/User/MKButton";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import SimpleFooter from "components/User/Footers/SimpleFooter";
import routes from "routes";
import bgImage from "assets/User/images/bg-sign-in-basic.jpeg";
import { createUser } from "request/api";
import { useSnackbar } from "context/Snackbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbar({ open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, [setSnackbar]);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true

    // Validation: Check if fields are filled
    if (!username.trim() || !email.trim() || !password.trim() || !confirmPassword.trim()) {
      setSnackbar({
        open: true,
        message: "Please fill all required fields.",
        color: "warning",
      });
      setLoading(false); // Set loading to false
      return;
    }

    // Validation: Check if passwords match
    if (password !== confirmPassword) {
      setSnackbar({
        open: true,
        message: "Passwords do not match.",
        color: "warning",
      });
      setLoading(false); // Set loading to false
      return;
    }

    // Validation: Check if terms are accepted
    if (!termsAccepted) {
      setSnackbar({
        open: true,
        message: "You must accept the terms and conditions.",
        color: "warning",
      });
      setLoading(false); // Set loading to false
      return;
    }

    // Escape characters
    const escapeHtml = (text) => {
      const element = document.createElement("div");
      if (text) {
        element.innerText = text;
      }
      return element.innerHTML;
    };

    const escapedUsername = escapeHtml(username);
    const escapedEmail = escapeHtml(email);
    const escapedPassword = escapeHtml(password);

    try {
      await createUser({
        username: escapedUsername,
        email: escapedEmail,
        password: escapedPassword,
      });
      setSnackbar({
        open: true,
        message: "User registered successfully!",
        color: "success",
      });
      navigate("/pages/authentication/sign-in");
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        error.message = "This user already exists";
      }
      setSnackbar({
        open: true,
        message: error.message,
        color: "error",
      });
    } finally {
      setLoading(false); // Ensure loading is set to false after API call
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
      >
        <CircularProgress color="success" />
      </Box>
    );
  }

  const token = localStorage.getItem("token");
  const isTokenValid = token ? true : false;
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        sticky
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        height="120vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item mt={12} xs={12} sm={10} md={8} lg={6} xl={4}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
              >
                <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#fff" }}>ELLE & LUI</span> - Register
                </MKTypography>
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <FacebookIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <GitHubIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <GoogleIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>
                  <MKBox mb={2}>
                    <TextField
                      type="text"
                      label="Username"
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <TextField
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label="Confirm Password"
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </MKBox>

                  <MKBox display="flex" alignItems="center" mb={2}>
                    <input
                      type="checkbox"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                    <MKTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      sx={{ cursor: "pointer", ml: 1 }}
                    >
                      I agree to the{" "}
                      <MKTypography
                        component={Link}
                        to="/terms"
                        variant="button"
                        color="success"
                        fontWeight="medium"
                        textGradient
                      >
                        terms and conditions
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="success" type="submit" fullWidth>
                      Register
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/pages/authentication/sign-in"
                        variant="button"
                        color="success"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                  <MKBox
                    mt={3}
                    mb={1}
                    textAlign="center"
                    sx={{ position: "relative", display: "flex", justifyContent: "center" }}
                  >
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <MKBox
                        display="flex"
                        alignItems="center"
                        sx={{
                          position: "absolute",
                          left: "25%",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "text.primary",
                          "&:hover": {
                            color: "success.main",
                            "& .MuiSvgIcon-root": {
                              transform: "translateX(-5px)",
                            },
                          },
                          transition: "color 0.3s ease",
                        }}
                      >
                        <ArrowBackIcon
                          sx={{
                            fontSize: "2rem",
                            transition: "transform 0.3s ease",
                          }}
                        />
                        <MKTypography
                          variant="button"
                          fontWeight="medium"
                          color="text"
                          sx={{ ml: 1 }}
                        >
                          Return Home
                        </MKTypography>
                      </MKBox>
                    </Link>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={1} mt={5}>
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default Register;

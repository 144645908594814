/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

/** 
  All of the routes for the ELLE ET LUI-EGALITE React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// ELLE ET LUI-EGALITE React layouts

import Dashboard from "layouts/Admin/dashboard";
import Users from "layouts/Admin/users";
import Articles from "layouts/Admin/Articles";
import Logout from "layouts/User/pages/authentication/Logout";

// @mui icons
import Icon from "@mui/material/Icon";

const adminroutes = [
  {
    type: "collapse",
    name: "Dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    icon: <Icon fontSize="small">supervised_user_circle</Icon>,
    route: "/dashboard/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Articles",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/dashboard/articles",
    component: <Articles />,
  },
  {
    type: "collapse",
    name: "Logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
  },
];

export default adminroutes;

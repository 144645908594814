// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// ELLE ET LUI -EGALITE React components
import MKTypography from "components/User/MKTypography";

// Images
import logoCT from "assets/User/images/logo-ct-dark.png";

const date = new Date().getFullYear();
export default {
  brand: {
    name: "ELLE ET LUI - EGALITE",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/association-elle-lui?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3Be1yZaJL7Rn%2BZgC2B6IXYEA%3D%3D",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/share/69XztKY6YHDfVDN7/?mibextid=qi2Omg",
    },
    {
      icon: <TwitterIcon />,
      link: "/#",
    },
    {
      icon: <YouTubeIcon />,
      link: "/#",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/pages/landing-pages/about-us" },
        { name: "Articles", href: "/pages/landing-pages/Articles" },
        { name: "Programs", href: "/pages/landing-pages/news" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "Gallery", href: "/pages/landing-pages/gallery" },
        { name: "Documentation", href: "/pages/landing-pages/resources" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/pages/landing-pages/contact-us" },
        { name: "knowledge center", href: "#" },
        { name: "sponsorships", href: "#" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/terms" },
        { name: "privacy policy", href: "/terms" },
        { name: "licenses", href: "/terms" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="bold">
      Copyright &copy; {date} . All rights reserved &nbsp;
      <MKTypography
        component="a"
        href="elleetlui-egalite.com/terms"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="bold"
        color="success"
      >
        by ELLE & LUI -EGALITE&nbsp; .
      </MKTypography>
      <br></br>
      <MKTypography variant="button" fontWeight="bold">
        Designed &nbsp;
        <MKTypography
          component="a"
          href="elleetlui-egalite.com"
          target="_blank"
          rel="website author, designer, web administrator, NSE3, web-vitals, Software Engineer"
          variant="button"
          fontWeight="bold"
          color="success"
        >
          by Claude Bernard Ndomane&nbsp; .
        </MKTypography>
      </MKTypography>
    </MKTypography>
  ),
};

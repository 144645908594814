import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectContext = createContext();

export const RedirectProvider = ({ children }) => {
  const [previousPath, setPreviousPath] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Capture the current path when the component mounts or path changes
    if (previousPath === null) {
      setPreviousPath(location.pathname);
    }
    // Clean up function to update the previous path
    return () => {
      setPreviousPath(location.pathname);
    };
  }, [location.pathname]);

  const redirectToPrevious = () => {
    if (previousPath) {
      navigate(previousPath);
      window.location.reload();
    } else {
      navigate("/"); // Redirect to home if no previous path
      window.location.reload();
    }
  };

  return (
    <RedirectContext.Provider value={{ redirectToPrevious }}>{children}</RedirectContext.Provider>
  );
};

RedirectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRedirect = () => useContext(RedirectContext);

/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

import Icon from "@mui/material/Icon";
// Import your existing components here
import ContactUs from "layouts/User/pages/landing-pages/Contact-us";
import Gallery from "layouts/User/pages/landing-pages/Gallery";
import Donate from "layouts/User/pages/landing-pages/Donate";
import Programs from "pages/User/LandingPages/Programs";
import JoinUs from "layouts/User/pages/landing-pages/JoinUs";
import News from "layouts/User/pages/landing-pages/News";
import Resources from "layouts/User/pages/landing-pages/Resources";
import Articles from "layouts/User/pages/landing-pages/Articles";
import SignIn from "layouts/User/pages/authentication/Sign-in";
import Register from "layouts/User/pages/authentication/Register";
import Logout from "layouts/User/pages/authentication/Logout";
import Missions from "pages/User/Home/Components/Missions";
import AboutUs from "pages/User/LandingPages/AboutUs";

const token = localStorage.getItem("token");
const isTokenValid = Boolean(token);

const routes = [
  {
    name: "About",
    icon: <Icon>info</Icon>,
    collapse: [
      {
        name: "Missions",
        icon: <Icon>assignment</Icon>,
        route: "/pages/landing-pages/missions",
        component: <Missions />,
      },

      {
        name: "Team",
        icon: <Icon>people</Icon>,
        route: "/pages/landing-pages/about-us",
        component: <AboutUs />,
      },
      {
        name: "Contact Us",
        icon: <Icon>contact_mail</Icon>,
        route: "/pages/landing-pages/contact-us",
        component: <ContactUs />,
      },
    ],
  },
  {
    name: "Blog",
    icon: <Icon>rss_feed</Icon>,
    route: "/pages/landing-pages/articles",
    component: <Articles />,
  },
  {
    name: "Resources",
    icon: <Icon>library_books</Icon>,
    collapse: [
      {
        name: "Gallery",
        icon: <Icon>photo_library</Icon>,
        route: "/pages/landing-pages/gallery",
        component: <Gallery />,
      },
      {
        name: "Podcasts",
        icon: <Icon>mic</Icon>,
        route: "/pages/landing-pages/resources",
        component: <Resources />,
      },
      {
        name: "Library",
        icon: <Icon>menu_book</Icon>,
        route: "/pages/landing-pages/resources",
        component: <Resources />,
      },
      {
        name: "Activity Calendar",
        icon: <Icon>calendar_today</Icon>,
        route: "/pages/landing-pages/resources",
        component: <Resources />,
      },
    ],
  },
  {
    name: "Get Involved",
    icon: <Icon>group</Icon>,
    collapse: [
      {
        name: "Join Us",
        icon: <Icon>group_add</Icon>,
        route: "/pages/landing-pages/join-us",
        component: <JoinUs />,
      },
      {
        name: "Become a Partner",
        icon: <Icon>handshake</Icon>,
        route: "/pages/landing-pages/join-us",
        component: <JoinUs />,
      },
      {
        name: "Become a Mentor",
        icon: <Icon>school</Icon>,
        route: "/pages/landing-pages/join-us",
        component: <JoinUs />,
      },
      {
        name: "Donate",
        icon: <Icon>volunteer_activism</Icon>,
        route: "/pages/landing-pages/donate",
        component: <Donate />,
      },
    ],
  },
  {
    name: "Programs",
    icon: <Icon>schedule</Icon>,
    route: "/pages/landing-pages/programs",
    component: <Programs />,
  },
  {
    name: "News",
    icon: <Icon>campaign</Icon>,
    collapse: [
      {
        name: "Newsletter & Social Media",
        icon: <Icon>email</Icon>,
        route: "/pages/landing-pages/news",
        component: <News />,
      },
      {
        name: "Partnerships & Press",
        icon: <Icon>groups</Icon>,
        route: "/pages/landing-pages/news",
        component: <News />,
      },
      {
        name: "Recent Testimonials",
        icon: <Icon>thumb_up</Icon>,
        route: "/pages/landing-pages/news",
        component: <News />,
      },
    ],
  },
  {
    name: "Account",
    icon: <Icon>person</Icon>,
    collapse: isTokenValid
      ? [
          {
            name: "Disconnect",
            icon: <Icon>logout</Icon>,
            route: "/logout",
            component: <Logout />,
          },
        ]
      : [
          {
            name: "Sign In",
            icon: <Icon>login</Icon>,
            route: "/pages/authentication/sign-in",
            component: <SignIn />,
          },
          {
            name: "Register",
            icon: <Icon>how_to_reg</Icon>,
            route: "/pages/authentication/register",
            component: <Register />,
          },
        ],
  },
];

export default routes;

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// ELLE ET LUI -EGALITE React pages
import ContactUs from "pages/User/LandingPages/ContactUs";

export default function ContactUsPage() {
  return <ContactUs />;
}

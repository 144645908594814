import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  // Show loader on initial load, route change, and navigation
  useEffect(() => {
    showLoader();

    // Hide loader after a short delay to simulate loading
    const timer = setTimeout(() => {
      hideLoader();
    }, 300); // Duration to simulate the loading effect

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="success" />
        </Box>
      )}
    </LoaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLoader = () => useContext(LoaderContext);

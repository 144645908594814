/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/ // src/components/UsersTableData.js
import React, { useEffect, useState } from "react";
import MDBox from "components/Admin/MDBox";
import MDTypography from "components/Admin/MDTypography";
import MDAvatar from "components/Admin/MDAvatar";
import MDBadge from "components/Admin/MDBadge";
import Icon from "@mui/material/Icon";
import { getUsers } from "request/api";

// Import Material Icons
import EditIcon from "@mui/icons-material/Edit";

const icon = <Icon>person</Icon>;

const UsersTableData = ({ onEdit }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers();
        const filteredUsers = data.filter((user) => user.roles.includes("user"));
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers(); // Initial fetch

    // Set interval to fetch users every 3 seconds
    const intervalId = setInterval(fetchUsers, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar>
        {icon} {/* Display icon inside MDAvatar */}
      </MDAvatar>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "No.", accessor: "number", width: "10%", align: "center" },
      { Header: "Avatar", accessor: "avatar", width: "20%", align: "left" },
      { Header: "Username", accessor: "username", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Status", accessor: "isVisible", align: "center" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: users.map((user, index) => ({
      number: <MDTypography variant="caption">{index + 1}</MDTypography>,
      avatar: <Author name={user.username} email={user.email} />,
      username: <MDTypography variant="caption">{user.username}</MDTypography>,
      email: <MDTypography variant="caption">{user.email}</MDTypography>,
      isVisible: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={user.isVisible ? "Active" : "Inactive"}
            color={user.isVisible ? "success" : "dark"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      createdAt: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {new Date(user.createdAt).toLocaleDateString()}
        </MDTypography>
      ),
      action: (
        <MDBox>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onEdit(user)}
          >
            <EditIcon />
          </MDTypography>
        </MDBox>
      ),
    })),
  };
};

export default UsersTableData;

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
const TeamImage =
  "https://www.ibcr.org/wp-content/uploads/2016/05/123FR-Pontanavat-Yonyot-scaled.jpg";
function Team() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} sx={{ textAlign: "center" }}>
          <MKBox component="img" src={TeamImage} alt="Team" width="100%" borderRadius="md" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKTypography variant="h3" fontWeight="bold" mb={2}>
            <span style={{ color: " #048404" }}>Team</span>
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Meet the <strong>dedicated</strong> and <em>passionate</em> team that works within our
            association to support and accompany young people in their <strong>personal</strong> and{" "}
            <strong>social</strong> development.
          </MKTypography>
        </Grid>
      </Grid>
    </>
  );
}

export default Team;

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// @mui material componentsimport React from 'react';

import React from "react";
import { Box, Grid, Card } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import routes from "routes";
import footerRoutes from "footer.routes";

const token = localStorage.getItem("token");
const isTokenValid = token ? true : false;

const Terms = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        sticky
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} mt="20vh">
            <Card>
              <MKBox
                id="terms"
                variant="gradient"
                bgColor="dark"
                borderRadius="md"
                coloredShadow="dark"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
              >
                <MKTypography variant="h2" fontWeight="medium" color="white" mt={1}>
                  <span style={{ color: "#FF5206" }}>ELLE & LUI</span> - Terms And Conditions Of Use
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <Box
                  sx={{
                    display: "block",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: 2,
                  }}
                >
                  <MKTypography variant="h5" color="text.primary" align="left" paragraph>
                    <strong style={{ color: "#28a745" }}>Last Updated: 23-08-2024_16:47</strong>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    Welcome to <strong style={{ color: "#28a745" }}>ELLE & LUI</strong>. These terms
                    and conditions outline the rules and regulations for the use of our website,
                    located at{" "}
                    <a href="https://elleetlui-egalite.com" style={{ color: "#FF5206" }}>
                      https://elleetlui-egalite.com
                    </a>
                    . By accessing this website, we assume you accept these terms and conditions. Do
                    not continue to use <strong style={{ color: "#28a745" }}>ELLE & LUI</strong> if
                    you do not agree to all of the terms and conditions stated on this page.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>1. Introduction</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    <strong style={{ color: "#28a745" }}>ELLE & LUI</strong> is committed to
                    promoting gender equity and equality through the content, services, and
                    community we provide. Our platform aims to educate, inspire, and empower
                    individuals to create a more just and equitable society. These terms apply to
                    all visitors, users, and others who access or use our website.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>2. Use of Content</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    All content provided on <strong style={{ color: "#28a745" }}>ELLE & LUI</strong>{" "}
                    is for informational purposes only. We strive to ensure that the information is
                    accurate, current, and useful. However, we do not guarantee the completeness,
                    reliability, or accuracy of any information on this site. The content is
                    provided <em>as is</em> without any warranties of any kind.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>3. User Responsibilities</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    <ul style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "20px" }}>
                      <li>
                        <strong style={{ color: "#28a745" }}>Respectful Conduct:</strong> As a user,
                        you agree to engage in respectful and constructive dialogue. Harassment,
                        hate speech, and discriminatory behavior are strictly{" "}
                        <span style={{ color: "#f44336" }}>prohibited</span>.
                      </li>
                      <li>
                        <strong style={{ color: "#28a745" }}>Account Security:</strong> If you
                        create an account on our website, you are responsible for maintaining the
                        confidentiality of your login credentials and for any activity that occurs
                        under your account.
                      </li>
                      <li>
                        <strong style={{ color: "#28a745" }}>Prohibited Uses:</strong> You may not
                        use our website to engage in any unlawful, harmful, or exploitative
                        activities. This includes, but is not limited to, hacking, spreading
                        malware, or infringing on the intellectual property rights of others.
                        Violations of this policy may result in{" "}
                        <span style={{ color: "#FF5206" }}>sanctions</span> including, but not
                        limited to, <span style={{ color: "#f44336" }}>account suspension</span> or{" "}
                        <span style={{ color: "#f44336" }}>termination</span>.
                      </li>
                    </ul>
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>4. Intellectual Property Rights</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    Unless otherwise stated,{" "}
                    <strong style={{ color: "#28a745" }}>ELLE & LUI</strong> and/or its licensors
                    own the intellectual property rights for all material on our website. All
                    intellectual property rights are reserved. You may access this material for
                    personal use, but you must not:
                    <ul style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "20px" }}>
                      <li>Republish material from our website.</li>
                      <li>Sell, rent, or sub-license material from our website.</li>
                      <li>Reproduce, duplicate, or copy material from our website.</li>
                      <li>Redistribute content from our website without prior written consent.</li>
                    </ul>
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>5. Third-Party Links</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    Our website may contain links to third-party websites that are not owned or
                    controlled by <strong style={{ color: "#28a745" }}>ELLE & LUI</strong>. We have
                    no control over, and assume no responsibility for, the content, privacy
                    policies, or practices of any third-party websites. You acknowledge and agree
                    that <strong style={{ color: "#28a745" }}>ELLE & LUI</strong> shall not be
                    responsible or liable for any damage or loss caused by or in connection with the
                    use of such content, goods, or services available on or through any such
                    websites.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>6. Limitation of Liability</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    In no event shall <strong style={{ color: "#28a745" }}>ELLE & LUI</strong>, its
                    directors, employees, or agents be liable for any indirect, incidental, special,
                    consequential, or punitive damages, including but not limited to, loss of
                    profits, data, use, goodwill, or other intangible losses, resulting from:
                    <ul style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "20px" }}>
                      <li>Your access to or use of or inability to access or use our website.</li>
                      <li>Any conduct or content of any third party on our website.</li>
                      <li>Any content obtained from our website.</li>
                      <li>
                        Unauthorized access, use, or alteration of your transmissions or content.
                      </li>
                    </ul>
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>7. Indemnification</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    You agree to defend, indemnify, and hold harmless{" "}
                    <strong style={{ color: "#28a745" }}>ELLE & LUI</strong> and its affiliates from
                    and against any claims, damages, obligations, losses, liabilities, costs, or
                    debt, and expenses (including but not limited to attorney&apos;s fees) arising
                    from:
                    <ul style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "20px" }}>
                      <li>Your use of and access to our website.</li>
                      <li>Your violation of any term of these Terms.</li>
                      <li>
                        Your violation of any rights of a third party, including but not limited to
                        any copyright, property, or privacy right.
                      </li>
                    </ul>
                    This indemnification obligation will survive the termination of these Terms and
                    your use of our website.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>8. Governing Law</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    These Terms shall be governed and construed in accordance with the laws of the
                    jurisdiction in which <strong style={{ color: "#28a745" }}>ELLE & LUI</strong>{" "}
                    operates, without regard to its conflict of law provisions. Our failure to
                    enforce any right or provision of these Terms will not be considered a waiver of
                    those rights.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>9. Changes to Terms</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    We reserve the right to modify or replace these Terms at any time. If a revision
                    is material, we will provide at least 30 days&apos; notice before any new terms
                    take effect. By continuing to access or use our website after those revisions
                    become effective, you agree to be bound by the revised terms.
                  </MKTypography>

                  <MKTypography variant="h4" color="text.primary" align="left" paragraph>
                    <span style={{ color: "#FF5206" }}>10. Contact Us</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" align="left" paragraph>
                    If you have any questions about these Terms, please contact us at{" "}
                    <a href="mailto:info@elleetlui-egalite.com" style={{ color: "#FF5206" }}>
                      info@elleetlui-egalite.com
                    </a>
                    .
                  </MKTypography>
                </Box>
                <Link to="/home" style={{ textDecoration: "none" }}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      mt: 5,
                      left: "4%",
                      transform: "translateY(-60%)",
                      color: "text.primary",
                      "&:hover": {
                        color: "info.main",
                        "& .MuiSvgIcon-root": {
                          transform: "translateX(-7px)",
                        },
                      },
                      transition: "color 0.3s ease",
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        fontSize: "3rem",
                        transition: "transform 0.3s ease",
                      }}
                    />
                    <MKTypography variant="h5" fontWeight="bold" color="text" sx={{ ml: 1 }}>
                      Return Home
                    </MKTypography>
                  </MKBox>
                </Link>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox mt={6} mb={3}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Terms;

/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import { Container } from "@mui/material";
const NewsImage =
  "https://img.freepik.com/vecteurs-premium/illustration-vectorielle-concept-actualites-ligne-bulletins-informations-informations-commerciales-marche_675567-6348.jpg";
function News() {
  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox component="img" src={NewsImage} alt="News" width="100%" borderRadius="md" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              <span style={{ color: " #048404" }}>News</span>
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Stay informed about the latest <strong>news</strong>, <em>press releases</em>, and{" "}
              <strong>events</strong> related to our association and the topics we defend.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default News;

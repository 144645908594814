/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
const ResourcesImage =
  "https://borgenproject.org/wp-content/uploads/Books-for-the-Worlds-Poor-1030x687.jpg";
function Resources() {
  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox
              component="img"
              src={ResourcesImage}
              alt="Resources"
              width="100%"
              borderRadius="md"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              <span style={{ color: " #048404" }}>Resources</span>
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Explore our <strong>resources</strong> to learn more about our initiatives, access
              helpful documents, and discover the <em>tools</em> we recommend to advance your
              projects.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Resources;

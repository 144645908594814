/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

// ELLE ET LUI -EGALITE React base styles
import typography from "assets/User/theme/base/typography";
import colors from "assets/User/theme/base/colors";

// ELLE ET LUI -EGALITE React helper functions
// import pxToRem from "assets/User/theme/functions/pxToRem";

const { size } = typography;
const { text } = colors;

export default {
  styleOverrides: {
    root: {
      fontSize: size.md,
      color: text.main,
    },
  },
};

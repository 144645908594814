/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
function ContactUs() {
  return (
    <>
      <MKBox pt={6} pb={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MKTypography variant="h3" fontWeight="bold" textAlign="center" mb={2}>
              <span style={{ color: " #048404" }}>Contact Us</span>
            </MKTypography>
            <MKTypography variant="body1" color="text" textAlign="center">
              Do you have any questions, ideas for collaboration or simply want to support us? Do
              not hesitate to contact us, we would be delighted to discuss with you.{" "}
              <strong>Email address</strong>:{" "}
              <a href="mailto:elleetlui.egalite@gmail.com" style={{ color: " #048404" }}>
                elleetlui.egalite@gmail.com
              </a>
              .
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default ContactUs;

/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App"; // Ensure the correct path
import SEO from "SEO"; // Ensure the correct path

// ELLE ET LUI-EGALITE React Context Provider
import { MaterialUIControllerProvider } from "./context"; // Ensure the correct path

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <SEO />
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
